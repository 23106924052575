/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
    var Sage;
    Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
                var anchorScroll = function(this_obj, that_obj, base_speed) {
                    var this_offset = this_obj.offset();
                    var that_offset = that_obj.offset();
                    var offset_diff = Math.abs(that_offset.top - this_offset.top);
                    var speed       = Math.ceil((offset_diff * base_speed) / 1000);

                    $("html,body").stop(true, false).animate({
                        scrollTop: that_offset.top
                    }, speed);
                };


                $('body').scrollspy({target: '#site-navigation', offset: 200});

                $('#site-navigation').find('a').on('click', function (event) {
                    event.preventDefault();

                    $('#site-navigation').find('li').removeClass('active');
                    var target = this.hash;
                    var navOffset = 0;
                    //dynamic offset
                    //navOffset = $('.site-header').height();
                    $('.toggle-menu').prop('checked', false);

                    anchorScroll($(this), $(target), 250 );

/*                    return $('html, body').animate({
                        scrollTop: $(this.hash).offset().top - navOffset}, 500, function(){
                    });*/
                });

                $('label.toggle-menu').on('click', function (event){
                   // event.preventDefault();
                    anchorScroll($(this), $('html,body'), 100 );
                   // return false;

                });

                var parallax = $(".parallax"),
                    speedP = 0.12;

                window.onscroll = function () {
                    parallax.each(function (index) {
                        var backPosX = 0;
                        if($("html").hasClass('lt-ie9')) { // html class... you know... it's useful
                            backPosX = $(this).css('background-position-x');
                        } else {
                            backPosX = $(this).css('backgroundPosition').split(' ')[0];
                        }

                        var elOffset = $(this).offset();
                        var windowYOffset = $(document).scrollTop() - elOffset.top,
                            elBackgrounPos = backPosX + (-1 * (120 + Math.floor((windowYOffset * speedP))) + "px");

                        $(this).css("backgroundPosition", elBackgrounPos);

                    });
                };

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                $('#kontakt').find('a[href="#tab-kontakt"]').tab('show');


                $(window).load(function() {
                    // Höhe von map iframe an textarea ende anpassen
                    var $iFrame, offsetIFrame, bottomIFrame, $txtArea, offsetTxtArea, bottomTxtArea;
                    $iFrame = $('#kontakt').find('iframe');
                    offsetIFrame = $iFrame.offset();

                    bottomIFrame = $iFrame.outerHeight() + offsetIFrame.top;
                    $txtArea = $('#kontakt').find('textarea');
                    offsetTxtArea = $txtArea.offset();
                    bottomTxtArea = $txtArea.outerHeight() + offsetTxtArea.top;
                    // + 6px padding und border von txtarea achtung evtl. Browser standard
                    $iFrame.css('height', $iFrame.height() + (bottomTxtArea - bottomIFrame));
                    //console.log(bottomTxtArea);
                });

            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
